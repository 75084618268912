<template>
  <div>
    <el-dialog
      :title="signup._id == undefined ? 'Invite User' : 'Edit User'"
      :visible.sync="documentActionModal"
      :width="getIsMobile ? '100%' : signup._id == undefined ? '30%' : '60%'"
      :class="getIsMobile ? 'companyuser-modal' : 'doc-modal'"
      
      v-loading="dLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :close-on-click-model="false"
      :close-on-press-escape="false"
    >
      <template v-if="signup._id">
        <el-form
          :model="signup"
          :rules="rules"
          ref="companyUser"
          class="log-form"
          @keyup.enter.native="submitForm()"
        >
          <el-row>
            <template>
              <el-col class="el-col-11" :span="11">
                <el-form-item label="First Name" prop="first_name">
                  <el-input
                    v-model="signup.first_name"
                    placeholder="Enter first name"
                  ></el-input>
                  <p class="error" v-if="getErrors && getErrors.first_name">
                    {{ getErrors.first_name }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col class="el-col-11 el-col-offset-1" :span="11">
                <el-form-item label="Last Name" prop="last_name">
                  <el-input
                    v-model="signup.last_name"
                    placeholder="Enter last name"
                  ></el-input>
                  <p class="error" v-if="getErrors && getErrors.last_name">
                    {{ getErrors.last_name }}
                  </p>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
          <el-row>
            <template>
              <el-col class="el-col-11" :span="11">
                <el-form-item label="Mobile" prop="phone">
                  <el-input
                    type="number"
                    v-model="signup.phone"
                    placeholder="989 989 9899"
                    autocomplete="off"
                  ></el-input>
                  <p class="error" v-if="getErrors && getErrors.phone">
                    {{ getErrors.phone }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col class="el-col-11 el-col-offset-1" :span="11">
                <el-form-item label="Email Address" prop="email">
                  <span class="text-red">*</span>
                  <el-input
                    type="email"
                    v-model="signup.email"
                    @blur="verifyDomainUser"
                    placeholder="Ex: johnwesley@abc.com"
                    autocomplete="off"
                  ></el-input>
                  <p class="error" v-if="getErrors && getErrors.email">
                    {{ getErrors.email }}
                  </p>
                  <p
                    class="error"
                    v-if="
                      getErrors &&
                      getErrors.critical_error &&
                      getErrors.critical_error ==
                        'Account with this email is already taken'
                    "
                  >
                    {{ getErrors.critical_error }}
                  </p>
                  <p class="error" v-if="isNotValidEmail">
                    Only Users from {{ brandingInfo.email_domain }} are allowed
                  </p>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
          <el-row v-if="!isEdit">
            <template>
              <el-col class="el-col-11" :span="11">
                <el-form-item label="Password" prop="password">
                  <el-input
                    :type="passwordType"
                    placeholder="Enter Password"
                    v-model="signup.password"
                    autocomplete="off"
                    ref="passwordInput"
                    @input="checkPassword"
                  ></el-input>
                  <span class="password-icon">
                    <img
                      class="password-switch"
                      v-if="passwordType == 'password'"
                      src="@/assets/img/icons/Invisibility.svg"
                      alt="icon"
                      @click="passwordType = 'text'"
                    />
                  </span>
                  <span class="password-icon">
                    <img
                      class="password-switch"
                      v-if="passwordType != 'password'"
                      src="@/assets/img/icons/visibility.svg"
                      alt="icon"
                      @click="passwordType = 'password'"
                    />
                  </span>
                  <p class="error" v-if="getErrors && getErrors.password">
                    {{ getErrors.password }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col class="el-col-11 el-col-offset-1" :span="11">
                <el-form-item label="Confirm Password" prop="cPassword">
                  <el-input
                    :type="cpasswordType"
                    placeholder="Enter Password"
                    v-model="signup.cPassword"
                    autocomplete="off"
                    ref="passwordInput"
                    @input="checkPassword"
                  ></el-input>
                  <span class="password-icon">
                    <img
                      class="password-switch"
                      v-if="cpasswordType == 'password'"
                      src="@/assets/img/icons/Invisibility.svg"
                      alt="icon"
                      @click="cpasswordType = 'text'"
                    />
                  </span>
                  <span class="password-icon">
                    <img
                      class="password-switch"
                      v-if="cpasswordType != 'password'"
                      src="@/assets/img/icons/visibility.svg"
                      alt="icon"
                      @click="cpasswordType = 'password'"
                    />
                  </span>
                  <p class="error" v-if="getErrors && getErrors.password">
                    {{ getErrors.password }}
                  </p>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
          <el-row>
            <span
              class="error"
              v-for="(err, index) in passwordError"
              :key="index"
            >
              {{ err }}<br />
            </span>

            <p
              class="error"
              v-if="
                getErrors &&
                getErrors.critical_error &&
                getErrors.critical_error !=
                  'Account with this email is already taken'
              "
            >
              {{ getErrors.critical_error }}
            </p>
          </el-row>
          <el-row>
            <template>
              <el-col class="el-col-11" :span="11">
                <el-form-item label="User Type" prop="user_type">
                  <el-select
                    class="el-col-24"
                    v-model="signup.user_type"
                    placeholder="User Type"
                  >
                    <el-option
                      v-for="(type, index) of userType"
                      :key="index"
                      :label="type.name"
                      :value="type._id"
                    ></el-option>
                  </el-select>
                  <p class="error" v-if="getErrors && getErrors.user_type">
                    {{ getErrors.user_type }}
                  </p>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
        </el-form>
      </template>
      <template v-if="signup._id == undefined">
        <el-form
          :model="signup"
          :rules="rules"
          ref="companyUser"
          class="log-form"
          @keyup.enter.native="submitForm()"
        >
          <el-row>
            <template>
              <el-col class="el-col-24" :span="11">
                <el-form-item label="Email Address" prop="email">
                  <el-input
                    type="email"
                    v-model="signup.email"
                    @blur="verifyDomainUser"
                    placeholder="Ex: johnwesley@abc.com"
                    autocomplete="off"
                  ></el-input>
                  <p class="error" v-if="getErrors && getErrors.email">
                    {{ getErrors.email }}
                  </p>
                  <p
                    class="error"
                    v-if="
                      getErrors &&
                      getErrors.critical_error &&
                      getErrors.critical_error ==
                        'Account with this email is already taken'
                    "
                  >
                    {{ getErrors.critical_error }}
                  </p>
                  <p class="error" v-if="isNotValidEmail">
                    Only Users from {{ brandingInfo.email_domain }} are allowed
                  </p>
                </el-form-item>
              </el-col>
              <el-col class="el-col-24" :span="11">
                <el-form-item label="User Type" prop="user_type">
                  <el-select
                    class="el-col-24"
                    v-model="signup.user_type"
                    placeholder="User Type"
                  >
                    <el-option
                      v-for="(type, index) of userType"
                      :key="index"
                      :label="type.name"
                      :value="type._id"
                    ></el-option>
                  </el-select>
                  <p class="error" v-if="getErrors && getErrors.user_type">
                    {{ getErrors.user_type }}
                  </p>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
        </el-form>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">Cancel</el-button>
        <el-button
          :disabled="
            getErrors &&
            (getErrors.email || getErrors.user_type || isNotValidEmail)
          "
          type="primary"
          @click="submitForm()"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <el-row>
          <el-col
            class="el-col el-col-xs-18 el-col-sm-18 el-col-md-18 el-col-lg-18"
          >
            <h3 class="title fw-normal mb-0 p-2">Company Users</h3>
          </el-col>
          <el-col
            class="
              el-col
              el-col-xs-4
              el-col-sm-4
              el-col-md-4
              el-col-lg-4
              el-el-col-offset-1
            "
          >
            <el-button type="primary"  @click="openDialog">
              <!-- <i class="el-icon-plus"></i> -->
              <i class="el-icon-position"></i>
              Invite User</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-form ref="form">
        <el-row>
          <el-col :span="24">
            <div class="card-body">
              <template v-if="users && users.length">
                <el-table :data="users" style="width: 100%" border>
                  <el-table-column prop="sno" label="S.No" width="60">
                    <template slot-scope="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="Name" width="150">
                    <template slot-scope="scope">
                      {{ scope.row.first_name + " " + scope.row.last_name }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="email" label="Email" width="200">
                    <template slot-scope="scope">
                      {{ scope.row.email }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="Status" width="80">
                    <template slot-scope="scope">
                      {{
                        scope.row.email_confirmed ? scope.row.status : "Pending"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="action" label="Action" width="200">
                    <template slot-scope="scope">
                      <ul class="action-buttons">
                        <!-- <li>
                          <el-button
                            plain
                            type="warning"
                            size="mini"
                            class="px-2"
                            @click="onEdit(scope.row)"
                          >
                            <i class="el-icon-edit"></i>
                          </el-button>
                        </li> -->

                        <li>
                          <el-button
                            plain
                            type="danger"
                            size="mini"
                            class="px-2"
                            @click="onDelete(scope.row._id)"
                          >
                            <i class="el-icon-delete"></i>
                          </el-button>
                        </li>
                      </ul>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </el-col>
        </el-row>
        <!-- <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button @click="goToDashboard">Cancel</el-button>
          </el-form-item>
        </div> -->
      </el-form>
    </div>
  </div>
</template>
<script>
// import router from "@/router";
import { mapGetters } from "vuex";
export default {
  name: "Company-Users",
  // metaInfo: {
  //   title: "eSigns - Company Users",
  //   meta: [
  //     { charset: "utf-8" },
  //     {
  //       name: "Company Users",
  //       content:
  //         "eSigns understands your business process better, faster, and quicker than the rest of the world to ensure hassle-free and paperless workflow management.",
  //     },
  //   ],
    //  titleTemplate: '%s | eSigns understands your business process better, faster, and quicker than the rest of the world to ensure hassle-free and paperless workflow management.'
  // },
  data() {
    return {
      loading: false,
      updatePersonalInformation: {
        first_name: "",
        last_name: "",
      },
      signup: {
        plan_type: "BUSINESS",
        email_confirmed: false,
        phone_confirmed: false,
        status: "ACTIVE",
        paid_user: false,
        account_status: true,
        company_id: undefined,
        password: "",
        cPassword: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        terms_and_conditions: true,
        user_type: "",
      },
      documentActionModal: false,
      password_rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "8 characters minimum.", regex: /.{6,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
        { message: "One special character required", regex: /[!@#$%^&*]/ },
      ],
      rules: {
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Invalid Email",
            trigger: "blur",
          },
        ],
        user_type: [
          {
            required: true,
            message: "User Type is required",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Phone is required",
            trigger: "blur",
          },
        ],
        first_name: [
          {
            required: true,
            message: "First name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Last name is required",
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "Company name is required",
            trigger: "blur",
          },
        ],
        terms_and_conditions: [
          {
            required: true,
            message: "Terms & conditions are need to be accept",
            trigger: "blur",
          },
        ],
        // cPassword: [
        //   {
        //     required: true,
        //     message: "Confirm Password is required",
        //     trigger: "blur",
        //   },
        // ],
      },
      passwordType: "password",
      cpasswordType: "password",
      passwordError: [],
      users: [],
      userType: [],
      dLoading: false,
      isEdit: false,
      userInfo: {},
      brandingInfo: {},
      isNotValidEmail: false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getAddCompanyUser",
      "getCompanyUsers",
      "getUserTypeList","getActiveWorkspace"
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
  },
  created() {
    this.userInfo = this.getAuthenticatedUser;
  },
  mounted() {
    this.$store.commit("auth/setUpdateProfileErrors", null, { root: true });
    this.getCompanyUsersList();
    this.getCompanyUserTypeList();
    this.getCompanyInfo();
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: "/" });
    },
    openDialog() {
      this.documentActionModal = true;
      this.$set(this.signup, "company_id", this.userInfo.company_id._id);
      this.$set(this.signup, "paid_user", this.userInfo.paid_user);
      this.$set(this.signup, "account_status", this.userInfo.account_status);
      this.$set(
        this.signup,
        "terms_and_conditions",
        this.userInfo.terms_and_conditions
      );
      this.$set(this.signup, "email_confirmed", this.userInfo.email_confirmed);
    },
    closeDialog() {
      this.documentActionModal = false;
      this.isNotValidEmail = false;
      this.resetForm();
      this.getCompanyUsersList();
    },
    submitForm() {
      this.dLoading = false;
      this.$refs.companyUser.validate((valid) => {
        if (valid) {
          if (!this.passwordError.length) {
            if (this.isEdit) {
              this.updateUser();
            } else {
              this.signUp();
            }
          }
        } else {
          this.dLoading = false;
          return false;
        }
      });
    },
    resetForm() {
      if (this.isEdit) {
        this.isEdit = false;
      }
      this.$refs.companyUser.resetFields();
    },
    async updateUser() {
      this.isEdit = true;
      try {
        delete this.signup.password;
        delete this.signup.cPassword;
        this.loading = true;
        let data = this.signup;
        // delete data.cPassword;
        await this.$store.dispatch("auth/updateCompanyUser", data);
        if (this.getAddCompanyUser) {
          if (
            this.getAddCompanyUser.success ||
            this.getAddCompanyUser.success == "true"
          ) {
            this.$notify.success({
              title: "Success",
              message: "Invite successfully sent to user",
            });
            this.documentActionModal = false;
            this.resetForm();
            this.getCompanyUsersList();
          } else {
            this.$notify.error({
              title: "Error",
              message: this.getAddCompanyUser.message,
            });
          }
        }
        this.dLoading = false;
        this.loading = false;
      } catch (err) {
        this.dLoading = false;
        this.loading = false;

        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async signUp() {
      try {
        this.loading = true;
        let data = this.signup;
        // delete data.cPassword;
        await this.$store.dispatch("auth/inviteCompanyUser", data);
        if (this.getAddCompanyUser) {
          if (
            this.getAddCompanyUser.success ||
            this.getAddCompanyUser.success == "true"
          ) {
            this.$notify.success({
              title: "Success",
              message: "Invite successfully sent to user",
            });
            this.documentActionModal = false;
            this.resetForm();
            this.getCompanyUsersList();
          } else {
            this.$notify.error({
              title: "Error",
              message: this.getAddCompanyUser.message,
            });
          }
        }
        this.dLoading = false;
        this.loading = false;
      } catch (err) {
        this.dLoading = false;
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    checkPassword() {
      this.passwordError = [];
      this.password_rules.forEach((rule) => {
        if (!rule.regex.test(this.signup.password)) {
          this.passwordError.push(rule.message);
        }
      });
      if (!this.passwordError.length && this.signup.cPassword.length) {
        if (this.signup.password != this.signup.cPassword) {
          this.passwordError.push("Password missmatch");
        } else {
          this.passwordError = [];
        }
      }
    },
    async getCompanyUsersList() {
      try {
        await this.$store.dispatch(
          "auth/getCompanyUsersList",
          this.userInfo.company_id
        );
        if (this.getCompanyUsers) {
          this.users = this.getCompanyUsers;
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    onEdit(user) {
      this.signup = user;
      this.documentActionModal = true;
      this.isEdit = true;
    },
    async onDelete(id) {
      this.$confirm("Are you sure to delete the user?")
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("auth/deleteCompanyUser", id);
          if (this.getAddCompanyUser) {
            this.$notify.success({
              title: "Success",
              message: "User deleted successfully ",
            });
            this.getCompanyUsersList();
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log("onDelete", err);
        });
    },
    async getCompanyUserTypeList() {
      try {
        await this.$store.dispatch("auth/getUserTypeList",{companyId:this.getActiveWorkspace.company_id});
        if (this.getUserTypeList) {
          this.userType = this.getUserTypeList;
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        // await this.$store.dispatch("company/fetchCompany");
        if (this.getCompanyDetails) {
          this.loading = false;
          this.brandingInfo = this.getCompanyDetails;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    verifyDomainUser() {
      if (this.brandingInfo.enforce_domain) {
        if (this.signup.email) {
          let domainName = this.signup.email.split("@")[1];
          if (this.brandingInfo.email_domain != domainName) {
            this.isNotValidEmail = true;
            this.$notify.error({
              title: "Error",
              message:
                "Only Users from " +
                this.brandingInfo.email_domain +
                " are allowed",
            });
          } else {
            this.isNotValidEmail = false;
          }
        }
      }
    },
  },
  beforeDestroy() {    
  },
};
</script>
<style lang="scss" scoped>
.password-switch {
  position: absolute;
  right: 0;
  top: 3.5em;
  color: #f754a2;
}
.error {
  color: red !important;
}
.companyuser-modal{
  width:100%
}
</style>